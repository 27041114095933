import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "My First Post",
  "date": "2021-07-23"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This is my first blog post! Isn't it `}<em parentName="p">{`great`}</em>{`?`}</p>
    <p>{`Some of my `}<strong parentName="p">{`favorite`}</strong>{` things are:`}</p>
    <ul>
      <li parentName="ul">{`Petting dogs`}</li>
      <li parentName="ul">{`Singing`}</li>
      <li parentName="ul">{`Eating potato-based foods`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      